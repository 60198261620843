var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { attrs: { title: _vm.$t("enviar-notificacao") } }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col" },
        [
          _c("vs-input", {
            attrs: { label: _vm.$t("usuario"), name: "userId" },
            model: {
              value: _vm.userId,
              callback: function ($$v) {
                _vm.userId = $$v
              },
              expression: "userId",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row mb-6 w-full" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { label: _vm.$t("mensagem"), name: "message" },
            model: {
              value: _vm.message,
              callback: function ($$v) {
                _vm.message = $$v
              },
              expression: "message",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full" },
        [
          _c(
            "vs-button",
            {
              staticClass: "float-right mr-2",
              on: { click: _vm.sendTextNotification },
            },
            [_vm._v(_vm._s(_vm.$t("enviar")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }