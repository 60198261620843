<template>
  <vx-card :title="$t('enviar-notificacao')">

      <div class="vx-row mb-6">
        <div class="vx-col">
          <vs-input
            :label="$t('usuario')"
            name="userId"
            v-model="userId"
          />
        </div>
      </div>

      <div class="vx-row mb-6 w-full">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            :label="$t('mensagem')"
            name="message"
            v-model="message"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button
            class="float-right mr-2"
            @click="sendTextNotification"
            >{{ $t('enviar') }}</vs-button
          >
        </div>
      </div>

  </vx-card>
</template>

<script>
import UserService from '@/services/api/UserService'

export default {
  props: {
    
  },
  data: () => ({
    userId: null,
    message: ''
  }),
  computed: {
    
  },
  methods: {
    sendTextNotification() {
      this.$vs.loading()
      this.userService.sendTextMessageNotification(this.userId, this.message).then((response) => {
        this.$vs.loading.close()
        this.notifySuccess(this.$vs, this.$t('mensagem-enviada-com-sucesso'))
      }, error => {
        this.$vs.loading.close()
      })
    }
  },
  mounted() {
    this.userService = UserService.build(this.$vs)
  }
}
</script>


<style lang="scss" scoped>
::v-deep .vs-popup {
  width: 800px !important;
}
</style>
